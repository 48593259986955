
import Field from './Field.js'
import { parseJsonValue, serializeJsonValue } from '../utils/json.js'


export default class StructField extends Field {

	type = 'STRUCT'
	_columnType = 'JSON'
	_gqlType = 'JSON'

	// options = {}

	constructor(initVals) {
		super(initVals)
	}

	get gqlType() {
		return `${this.collectionId}_${this.fieldId}`
		// return this.config.multi ? `[${typeName}]` : typeName
	}

	static parseValue(value) {
		return parseJsonValue(value)
	}

	static serializeValue(value) {
		return serializeJsonValue(value)
	}

	_parseValue(value) {
		return JsonField.parseValue(value)
	}

	_serializeValue(value) {
		return JsonField.serializeValue(value)
	}
}